@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dosis/v26/HhyJU5sn9vOmLxNkIwRSjTVNWLEJabMV3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dosis/v26/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dosis/v26/HhyJU5sn9vOmLxNkIwRSjTVNWLEJBbMV3A.ttf) format('truetype');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dosis/v26/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ6bQV3A.ttf) format('truetype');
}
.lang-menu {
  visibility: hidden;
  width: 0;
}
.navAnimated {
  text-align: right;
  font-weight: 600;
  font-size: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
@media (max-width: 1194px) {
  .navAnimated {
    font-size: 16px;
  }
}
@media (max-width: 885px) {
  .navAnimated {
    font-size: 13px;
  }
}
.navAnimated * {
  box-sizing: border-box;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.navAnimated li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1.5%;
  overflow: hidden;
}
@media (max-width: 1194px) {
  .navAnimated li {
    margin: 0 0.9%;
  }
}
@media (max-width: 885px) {
  .navAnimated li {
    margin: 0 0.8%;
  }
}
.navAnimated a {
  padding: 0.3em 0;
  color: #000;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.navAnimated a:before,
.navAnimated a:after {
  position: absolute;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.navAnimated a:before {
  bottom: 100%;
  display: block;
  height: 3px;
  width: 100%;
  content: "";
  background-color: #1791D8;
}
.navAnimated a:after {
  padding: 0.3em 0;
  position: absolute;
  bottom: 100%;
  left: 0;
  content: attr(data-hover);
  color: #1791D8;
  white-space: nowrap;
}
.navAnimated li:hover a,
.navAnimated > .active a {
  transform: translateY(100%);
}
.btnGetInTouch {
  display: inline-block;
  margin: 0 !important;
  position: relative;
}
.btnGetInTouch a {
  background: transparent linear-gradient(265deg, #73CBFD 0%, #1791D8 100%) 0% 0% no-repeat padding-box;
  font-size: 18px;
  color: #FFFFFF;
  padding: 16px 40px 17px 40px;
  border-radius: 6px;
  font-weight: 500;
  transform: translateY(0) !important;
  border-top: 0;
}
@media (max-width: 1130px) {
  .btnGetInTouch a {
    padding: 6px 7px 6px 7px;
    font-size: 16px;
  }
}
@media (max-width: 885px) {
  .btnGetInTouch a {
    font-size: 13px;
  }
}
.btnGetInTouch a:before {
  height: 0;
}
.btnGetInTouch a:hover {
  border-radius: 50px;
  transition: 0.3s;
  background: transparent linear-gradient(1deg, #2e92d9 0%, #1791d8 100%) 0% 0% no-repeat padding-box;
}
.btnGetInTouch a:hover::after {
  border: none;
}
.scrollToTop {
  width: 50px;
  height: 50px;
  background: #f7f7f7;
  text-align: center;
  position: fixed;
  bottom: 20px;
  right: 11px;
  border-radius: 10px;
}
.scrollToTop img {
  max-width: 10px;
  transform: rotate(90deg);
  margin-top: 15px;
}
.scrollToTop:hover img {
  filter: invert(1);
  transition: 0.3s;
}
.scrollToTop a {
  display: block;
  height: 100%;
  cursor: pointer;
}
.navAnimated li.lang-menu {
  padding: 0;
  margin: 0;
}
.navAnimated li.lang-menu:hover {
  transition: initial;
}
.navAnimated li.lang-menu:hover a {
  transform: initial;
}
.navAnimated li.lang-menu .languages-switch {
  width: 75px;
  height: 81px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.navAnimated li.lang-menu .languages-switch a {
  display: block;
  text-decoration: none;
  padding: 1px 0;
  font-size: 15px;
  color: #CECECE;
  position: absolute;
  width: 100%;
  display: none;
}
.navAnimated li.lang-menu .languages-switch a::before {
  display: none;
}
.navAnimated li.lang-menu .languages-switch a::after {
  display: none;
}
.navAnimated li.lang-menu .languages-switch a[data-postion~="1"] {
  top: 7px;
}
.navAnimated li.lang-menu .languages-switch a[data-postion~="2"] {
  top: 27px;
}
.navAnimated li.lang-menu .languages-switch a[data-postion~="3"] {
  top: 53px;
}
.navAnimated li.lang-menu .languages-switch a.actived {
  font-size: 19px;
  color: #000000;
  display: block;
}
.navAnimated li.lang-menu .languages-switch:hover {
  background-image: url("../images/img_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.navAnimated li.lang-menu .languages-switch:hover a[data-postion~="1"] {
  display: block;
}
.navAnimated li.lang-menu .languages-switch:hover a[data-postion~="3"] {
  display: block;
}
text:hover {
  filter: invert(1);
  transition: 0.3s;
  cursor: pointer;
}
.phpActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  font-size: 70px;
}
.htaccessActive {
  transition: 2.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(622px, 171px) rotate(-90deg);
}
.windowsActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(343px, 227px);
}
.angularActive {
  transition: 2s;
  animation-delay: 0.3s;
  font-size: 62px;
}
.postgresqActive {
  transition: 2s;
  animation-delay: 3.5s;
  font-size: 30px;
}
.winformsActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(1018px, 349px) rotate(-90deg);
}
.powershellActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(383px, 525px);
}
.cvsmocosActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(114px, 412px);
}
.aspnetmvcActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(572px, 556px) rotate(-90deg);
}
.gitActive {
  transition: 1.6s;
  animation-delay: 0.1s;
  opacity: 1;
  transform: translate(1038px, 442px);
}
.laravelActive {
  transition: 3.5s;
  animation-delay: 3s;
  font-size: 74px;
  opacity: 1;
  transform: translate(290px, 496px);
}
.pythonActive {
  transition: 4s;
  animation-delay: 2.7s;
  font-size: 70px;
}
.jqueryActive {
  transition: 3s;
  animation-delay: 2s;
  font-size: 30px;
}
.wordpressActive {
  transition: 3.5s;
  animation-delay: 5s;
  transform: translate(771px, 534px) !important;
  opacity: 1;
}
.kotlinActive {
  animation-delay: 5s;
  transform: translate(292px, 307px);
  font-size: 70px;
  transition: 4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.javascriptActive {
  transition: 3s;
  animation-delay: 2s;
  opacity: 1;
  font-size: 82px;
}
.springActive {
  transition: 3s;
  animation-delay: 2s;
  opacity: 1;
  transform: translate(119px, 321px) rotate(-90deg);
}
.loopsActive {
  transition: 3s;
  animation-delay: 2s;
  opacity: 1;
  transform: translate(76px, 347px);
}
.typescriptActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
}
.angularjsActive {
  transition: 2.6s;
  animation-delay: 5s;
  opacity: 1;
}
.nodeActive {
  transition: 3.2s;
  animation-delay: 10s;
  opacity: 1;
}
.vueActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
}
.web-servicesActive {
  transition: 2.5s;
  animation-delay: 2s;
  opacity: 1;
  transform: translate(296px, 205px);
}
.React-nativeActive {
  transition: 2.5s;
  animation-delay: 2s;
  opacity: 1;
  transform: translate(793px, 568px);
}
.mysqlActive {
  transition: 2.5s;
  animation-delay: 2s;
  opacity: 1;
  transform: translate(205px, 244px);
}
.vueActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
  font-size: 41px;
}
.cssActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
  font-size: 41px;
}
.cPlusActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
  font-size: 41px;
}
.cshirpActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
  font-size: 41px;
}
.mongoActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
  font-size: 41px;
}
.djangoActive {
  transition: 5s;
  animation-delay: 15s;
  opacity: 1;
  font-size: 30px;
}
.objectivecActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(599px, 180px) rotate(-90deg);
}
.excelvbaActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(521px, 138px);
}
.oracolActive {
  transition: 5s;
  animation-delay: 2.6s;
  opacity: 1;
}
.unit-testingActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(402px, 174px);
}
.shellActive {
  transition: 7s;
  animation-delay: 7s;
  opacity: 1;
}
.firebaseMultithreadin {
  transition: 7s;
  animation-delay: 7s;
  opacity: 1;
}
.google-map {
  transition: 7s;
  animation-delay: 7s;
  opacity: 1;
}
.react-js {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(998px, 336px) rotate(-90deg);
}
.rubyonrailsActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(556px, 562px) rotate(-90deg);
}
.sqliteActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
}
.imageActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(643px, 611px);
}
.hibernateActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(730px, 609px);
}
.sqlserverActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(346px, 547px);
}
.aspnetActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(444px, 610px);
}
.androidstudioActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(865px, 193px);
}
.algorithmActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(838px, 169px);
}
.performanceActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(1043px, 351.5px) rotate(-90deg);
}
.validationActive {
  transition: 3s;
  animation-delay: 0s;
  opacity: 1;
  transform: translate(143px, 331px) rotate(-90deg);
}
html {
  scroll-behavior: smooth;
}
#mobileNavbar,
#mySidenav {
  display: none;
}
@keyframes grow-shrink {
  0% {
    transform: translate3d(0px, -5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  100% {
    transform: translate3d(0px, 5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
}
@keyframes shrink-grow {
  0% {
    transform: translate3d(0px, 5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  100% {
    transform: translate3d(0px, -5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
}
@keyframes typing {
  0% {
    width: 0;
  }
  40% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  75% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #1791D8;
  }
}
/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(-100%);
  }
  to {
    -moz-transform: translateY(0%);
  }
}
/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0%);
  }
}
@keyframes my-animation {
  from {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
/* for Firefox */
@-moz-keyframes my-animation1 {
  from {
    -moz-transform: translateY(0%);
  }
  to {
    -moz-transform: translateY(100%);
  }
}
/* for Chrome */
@-webkit-keyframes my-animation1 {
  from {
    -webkit-transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(100%);
  }
}
@keyframes my-animation1 {
  from {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes witr_abmovetb_box15 {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@-webkit-keyframes witr_movelr_box45 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(35px);
    transform: translateX(35px);
  }
}
@keyframes witr_movelr_box45 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(35px);
    transform: translateX(35px);
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
body {
  font-family: 'Dosis', sans-serif;
  background-image: url("../images/img_top_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.container {
  max-width: 1280px;
  margin: 0px auto;
}
/*topSection*/
.topSection {
  /*Sticky*/
}
.topSection .container {
  max-width: 1280px;
  margin: 0px auto;
}
.topSection .header {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 111;
  max-width: 100%;
}
.topSection .header .headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
}
.topSection .header .headerContainer .logo {
  width: 20%;
  padding: 12px 0 0px 85px;
}
.topSection .header .headerContainer .logo img {
  width: 100%;
  transition: 0.3s all;
}
.topSection .header .headerContainer .mainMenu {
  width: 80%;
  text-align: right;
  padding: 0px 85px 0 10px;
}
.topSection .sticky {
  background: white;
  box-shadow: 2px 2px 5px #00000038;
}
.topSection .sticky .headerContainer {
  align-items: center;
  padding: 10px 0;
}
.topSection .sticky .headerContainer .logo {
  padding-top: 0px;
}
.topSection .sticky .headerContainer .logo img {
  max-width: 130px;
  transition: 0.3s all;
  top: initial;
  transform: initial;
}
.topSection .sticky .headerContainer .mainMenu {
  padding: 0px 85px 0px 0;
  transition: 0.3s all;
}
.topSection .pcbText {
  justify-content: center;
  align-items: center;
  display: inline-block;
}
.topSection .pcbText p {
  font-size: 50px;
  animation: typing 3s steps(56), blink-caret 0.85s step-end infinite;
  overflow: hidden;
  white-space: nowrap;
  color: #3B3663;
  border-right: 2px solid #1791D8;
  font-size: 55px;
  color: #1791D8;
  text-transform: capitalize;
  font-weight: 700;
  padding: 3px 0 0 0;
  display: inline-block;
}
@media (max-width: 1279px) {
  .topSection .pcbText p {
    font-size: 4vw;
  }
}
.topSection .topContain {
  padding: 0;
  padding-bottom: 20.38%;
}
@media (max-width: 768px) {
  .topSection .topContain {
    padding-bottom: 15.38%;
  }
}
.topSection .topContain .pioneer {
  float: left;
  width: 54.1%;
  padding: 46px 0 0 6.65%;
  /*Text Slider*/
}
.topSection .topContain .pioneer .slide {
  display: none;
}
.topSection .topContain .pioneer .visible {
  display: block;
  width: 100%;
  opacity: 100;
}
.topSection .topContain .pioneer .dot {
  display: inline-block;
  cursor: pointer;
}
.topSection .topContain .pioneer .pioneerText {
  height: 143px;
  overflow: hidden;
  margin: 9px 0px 11px;
}
.topSection .topContain .pioneer .pioneerText ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.topSection .topContain .pioneer .pioneerText ul li {
  height: 60px;
}
.topSection .topContain .pioneer .pioneerText p {
  display: block;
  text-decoration: none;
  font-size: 53px;
  color: #3B3663;
  font-weight: 700;
  /* animation properties */
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -moz-animation: my-animation 1s ease-in-out 1;
  -webkit-animation: my-animation 1s ease-in-out 1;
  animation: my-animation 1s ease-in-out 1;
  animation-fill-mode: forwards;
}
@media (max-width: 1279px) {
  .topSection .topContain .pioneer .pioneerText p {
    font-size: 4vw;
  }
}
.topSection .topContain .pioneer .technology {
  height: 7vw;
  width: 100%;
  float: left;
}
@media (max-width: 1279px) {
  .topSection .topContain .pioneer .technology {
    height: 7vw;
  }
}
.topSection .topContain .pioneer .technology #typewriter,
.topSection .topContain .pioneer .technology .element,
.topSection .topContain .pioneer .technology .element2,
.topSection .topContain .pioneer .technology .element3,
.topSection .topContain .pioneer .technology .element4,
.topSection .topContain .pioneer .technology .element5 {
  display: flex;
  height: auto;
  float: left;
}
.topSection .topContain .pioneer .technology #typewriter span,
.topSection .topContain .pioneer .technology .element span,
.topSection .topContain .pioneer .technology .element2 span,
.topSection .topContain .pioneer .technology .element3 span,
.topSection .topContain .pioneer .technology .element4 span,
.topSection .topContain .pioneer .technology .element5 span {
  display: block;
}
.topSection .topContain .pioneer .technology #typewriter-prefix {
  text-align: left;
  color: #E8175D;
}
.topSection .topContain .pioneer .technology #typewriter-suffix,
.topSection .topContain .pioneer .technology .typed-cursor {
  background-color: #1791D8;
  height: 42px;
  width: 2px;
  float: left;
}
@media (max-width: 1279px) {
  .topSection .topContain .pioneer .technology #typewriter-suffix,
  .topSection .topContain .pioneer .technology .typed-cursor {
    height: 6vw;
  }
}
.topSection .topContain .pioneer .technology #typewriter-text,
.topSection .topContain .pioneer .technology h1 {
  font-size: 55px;
  color: #1791D8;
  text-transform: capitalize;
  font-weight: 700;
  padding: 3px 0 0 0;
}
@media (max-width: 1279px) {
  .topSection .topContain .pioneer .technology #typewriter-text,
  .topSection .topContain .pioneer .technology h1 {
    font-size: 4vw;
  }
}
.topSection .topContain .pioneer .description {
  margin: 45px 94px 36px 0;
}
@media (max-width: 1279px) {
  .topSection .topContain .pioneer .description {
    margin: 33px 51px 22px 0;
  }
}
.topSection .topContain .pioneer .description .lineUp {
  font-size: 24px;
  font-weight: 500;
  color: #726F8C;
}
@media (max-width: 1279px) {
  .topSection .topContain .pioneer .description .lineUp {
    font-size: 1.2em;
  }
}
.topSection .topContain .pioneer .read-more a {
  display: block;
  max-width: 163px;
  text-align: center;
  text-decoration: none;
  background: transparent linear-gradient(265deg, #3BBBBF 0%, #1791D8 100%) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 500;
  padding: 16px 0 17px;
}
.topSection .topContain .pioneer .read-more a:hover {
  background: transparent;
  border: 1px solid #3BBBBF;
  color: #333;
  box-shadow: 4px 3px 9px 0px #1791d8;
  transition: 0.3s;
}
.topSection .topContain .imagesBox {
  float: left;
  width: 42.9%;
  padding: 0 0 0 3.6%;
  position: relative;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 1279px) {
  .topSection .topContain .imagesBox {
    padding: 0 0px 0 4%;
    width: 41.9%;
  }
}
.topSection .topContain .imagesBox .centerImage {
  width: 100%;
  background: transparent;
  z-index: 10;
  margin-top: 22%;
}
.topSection .topContain .imagesBox .mouseMovement {
  will-change: transform;
  --translate-x: 0px;
  --translate-y: 0px;
  transform: translate3d(0px, 0px, 0px);
  position: relative;
  display: block;
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: 100%;
}
.topSection .topContain .imagesBox .imageAnimateNew {
  width: 16%;
  height: 22%;
  background: transparent;
  position: absolute;
  top: 4%;
  left: 8%;
  animation: grow-shrink 1.5s infinite alternate;
  transform-style: preserve-3d;
  will-change: transform;
  z-index: 10;
}
.topSection .topContain .imagesBox .imageAnimateNew img {
  width: 100%;
}
.topSection .topContain .imagesBox .imageAnimateNew.upsideDown {
  animation: shrink-grow 1.5s infinite alternate;
}
.topSection .topContain .imagesBox .firstPart {
  width: 14%;
  height: 18%;
  top: 10%;
  left: 51%;
}
.topSection .topContain .imagesBox .secondPart {
  width: 15%;
  height: 22%;
  top: 31%;
  left: 84%;
}
@media (max-width: 768px) {
  .topSection .topContain .imagesBox .secondPart {
    left: 89%;
  }
}
.topSection .topContain .imagesBox .thirdPart {
  width: 9%;
  height: 22%;
  top: 69%;
  left: 81%;
}
@media (max-width: 768px) {
  .topSection .topContain .imagesBox .thirdPart {
    left: 85%;
  }
}
.topSection .topContain .imagesBox .fourthPart {
  width: 30%;
  height: 22%;
  top: 17%;
  left: 11%;
}
@media (max-width: 768px) {
  .topSection .topContain .imagesBox .fourthPart {
    left: 6%;
  }
}
.topSection .topContain .imagesBox .fifthPart {
  width: 20%;
  height: 22%;
  top: 64%;
  left: 87%;
}
@media (max-width: 768px) {
  .topSection .topContain .imagesBox .fifthPart {
    left: 87%;
  }
}
.topSection .topContain .imagesBox .wavePart {
  width: 29%;
  height: 22%;
  top: 18%;
  left: 24%;
}
@media (max-width: 768px) {
  .topSection .topContain .imagesBox .wavePart {
    left: 23%;
  }
}
.topSection .topContain .imagesBox .sixthPart {
  width: 13%;
  height: 22%;
  top: 56%;
  left: 2%;
}
.topSection .topContain .imagesBox .image_animate {
  width: 100%;
  background: transparent;
  position: relative;
  animation: witr_abmovetb_box15 3s linear 2s infinite alternate running;
  transform-style: preserve-3d;
  will-change: transform;
  z-index: 10;
}
.topSection .topContain .imagesBox img {
  max-width: 446px;
}
.topSection .topContain .slidingElements {
  position: relative;
  width: 51%;
  float: left;
  margin: 0 auto;
  /* top: -12%; */
  left: 46.5%;
  z-index: 10;
  min-height: 190px;
}
.topSection .topContain .slidingElements .slidingImage {
  width: 12%;
  height: 22%;
  background: transparent;
  position: absolute;
  top: -70%;
  left: 22%;
  animation: witr_movelr_box45 5s linear 1s infinite alternate running;
  transform-style: preserve-3d;
  will-change: transform;
  z-index: 10;
}
.topSection .topContain .slidingElements .slidingImage img {
  width: 100%;
  max-width: 182px;
}
.topSection .topContain .slidingElements .slidingImage.second {
  width: 14%;
  height: 37%;
  top: -50%;
  left: 60.3%;
}
.topSection .topContain .slidingElements .slidingImage.second img {
  max-width: 302px;
}
.topSection .bottom-contain {
  padding: 177px 0 0 0;
}
.topSection .bottom-contain h1 {
  color: #2FB7BC;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 0px 0 0 48px;
}
.topSection .bottom-contain p {
  text-align: center;
  font-size: 38px;
  color: #3B3663;
  font-weight: 700;
  padding: 9px 0 59px 46px;
}
/*end topSection*/
/*serviceSection*/
.serviceSection {
  padding-bottom: 7%;
}
.serviceSection .container {
  padding: 0 85px 0 85px;
}
.serviceSection .container .titleSection {
  text-align: center;
  padding-top: 60px;
}
.serviceSection .container .titleSection h3 {
  color: #2FB7BC;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 0px 0 0 48px;
}
.serviceSection .container .titleSection p {
  text-align: center;
  font-size: 38px;
  color: #3B3663;
  font-weight: 700;
  padding: 9px 0 92px 46px;
}
.serviceSection .container .banners {
  width: 100%;
  margin: 74px auto 0;
  vertical-align: middle;
  max-width: 1280px;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .serviceSection .container .banners {
    margin: 31px auto 0;
  }
}
.serviceSection .container .banners .banner {
  width: 100%;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
}
.serviceSection .container .banners .banner .owl-item {
  padding-bottom: 36px;
}
.serviceSection .container .banners .banner .owl-item .bannerItem {
  padding: 2.99% 2.4% 3% 2.2%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #1D94D927;
  border-radius: 14px;
  margin: auto 2.7% auto 0%;
  vertical-align: middle;
  text-align: center;
  box-shadow: 6px 32px 37px -40px #1791d8;
  min-height: 485px;
}
.serviceSection .container .banners .banner .owl-item .bannerItem .bannerItemImg {
  text-align: center;
  padding: 0 0 10px 0;
}
.serviceSection .container .banners .banner .owl-item .bannerItem .bannerItemImg.wide {
  text-align: center;
}
.serviceSection .container .banners .banner .owl-item .bannerItem img {
  width: 100%;
}
@media (max-width: 768px) {
  .serviceSection .container .banners .banner .owl-item .bannerItem {
    float: none;
    width: 100%;
    max-width: initial;
    display: block;
    margin: 0px 0 8px 1px;
  }
  .serviceSection .container .banners .banner .owl-item .bannerItem img {
    max-width: 444px;
    margin: 0px auto;
  }
}
.serviceSection .container .banners .banner .owl-item .bannerItem h2 {
  font: normal normal bold 25px/34px Dosis;
  letter-spacing: 0px;
  color: #2FB7BC;
  margin-bottom: 7%;
}
.serviceSection .container .banners .banner .owl-item .bannerItem p {
  font: normal normal normal 18px/29px Dosis;
  letter-spacing: 0px;
  color: #6E6B89;
  padding: 0 0 10px 0;
}
/* About us section */
.aboutUsSection {
  padding: 114px 0;
}
.aboutUsSection .container {
  padding: 0 85px;
}
.aboutUsSection .container .aboutSectionDflex {
  display: flex;
  justify-content: space-between;
}
.aboutUsSection .container .aboutSectionDflex .leftContent {
  max-width: 339px;
  width: 100%;
  margin-top: 50px;
}
.aboutUsSection .container .aboutSectionDflex .leftContent h3 {
  font-size: 20px;
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
}
.aboutUsSection .container .aboutSectionDflex .leftContent h2 {
  margin-top: 9px;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3B3663;
}
.aboutUsSection .container .aboutSectionDflex .rightContent {
  max-width: 650px;
  width: 100%;
  position: relative;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect:before {
  background: #41B7F8;
  content: "";
  position: absolute;
  top: 32px;
  bottom: -8px;
  left: 34px;
  width: 3px;
  z-index: 0;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect:after {
  background: url('../images/connect-arrow.png') no-repeat 0 0;
  content: "";
  position: absolute;
  bottom: -48px;
  left: 15px;
  height: 27px;
  width: 40px;
  z-index: 0;
  display: none;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle {
  cursor: pointer;
  color: #909090;
  font-size: 20px;
  line-height: 55px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 920px) {
  .aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle {
    font-size: 19px;
  }
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle i {
  border-radius: 100%;
  display: block;
  float: left;
  margin-right: 30px;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  height: 55px;
  width: 69px;
  z-index: 99;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle i:before {
  content: "";
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle i:after {
  background: #e1e1e1;
  border-radius: 100%;
  height: 26px;
  width: 26px;
  content: "";
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle .spinxAccoContent {
  display: none;
  overflow: hidden;
  padding: 15px 0 10px 100px;
  width: 100%;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle .spinxAccoContent p {
  margin: 0;
  font: normal normal normal 17px/29px Dosis;
  letter-spacing: 0px;
  color: #8E8E8E;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle .spinxAccoContent h2 {
  margin: 0;
  font: normal normal normal 17px/29px Dosis;
  letter-spacing: 0px;
  color: #333;
  font-weight: bold;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle .spinxAccoContent ul {
  margin-left: 5%;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle .spinxAccoContent ul li {
  margin: 0;
  font: normal normal normal 17px/29px Dosis;
  letter-spacing: 0px;
  color: #8E8E8E;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .spinxAccoTitle .showContent {
  display: block;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current {
  font-size: 30px;
  line-height: 69px;
  color: #41B7F8;
  letter-spacing: 0px;
}
@media (max-width: 920px) {
  .aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current {
    font-size: 19px;
  }
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current i {
  background: #41B7F8;
  height: 69px;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current i:before {
  background: url('../images/aboutus_icons/vector_icon.png') no-repeat;
  z-index: 9;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current .icon_01:before {
  background-position: 3px 2px !important;
  height: 40px !important;
  width: 40px !important;
  background-color: #41B7F8;
  background-size: cover;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current .icon_01:after {
  background: transparent !important;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current .icon_02:before {
  background-position: -38px 0 !important;
  height: 40px !important;
  width: 40px !important;
  background-size: cover;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current .icon_02:after {
  background: transparent !important;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current .icnEvolve:before {
  background-position: -80px 0;
  height: 40px;
  width: 40px;
  background-size: cover;
}
.aboutUsSection .container .aboutSectionDflex .rightContent .spinxConnect .current .icnEvolve:after {
  background: transparent !important;
}
/*skillSection*/
.skillSection {
  padding-top: 5.4%;
  overflow: hidden;
}
.skillSection .container .headingSction h2 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0 0 7px 0;
  margin: 0;
}
.skillSection .container .headingSction p {
  text-align: center;
  font-size: 38px;
  color: #3B3663;
  font-weight: 700;
  padding: 9px 0 0px 0px;
}
.skillSection .container .innerImages img {
  width: 100%;
  max-width: 1280px;
}
/*qualifiedSection*/
.qualifiedSection {
  padding: 0 0;
  margin-top: 11%;
}
.qualifiedSection .container {
  padding: 0 85px;
}
.qualifiedSection .container .ourQuilifiedContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.qualifiedSection .container .payFor {
  width: 47%;
}
.qualifiedSection .container .payFor .title h2 {
  color: #73CBFD;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 8px 0;
}
.qualifiedSection .container .payFor .title h3 {
  margin: 0;
  padding: 0 0 36px 0;
  font-size: 38px;
  font-weight: 700;
}
.qualifiedSection .container .payFor .description p {
  font-size: 18px;
  color: #6E6B89;
}
.qualifiedSection .container .payFor .description p.first {
  padding: 0 0 20px 0;
  line-height: 33px;
}
.qualifiedSection .container .payFor .description p.last {
  font-weight: 700;
  padding: 0 0 60px 0;
  line-height: 27px;
}
.qualifiedSection .container .payFor .description .getInTouch {
  background: transparent linear-gradient(265deg, #73CBFD 0%, #1791D8 100%) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: block;
  text-decoration: none;
  max-width: 163px;
  text-align: center;
  padding: 16px 0 17px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
}
.qualifiedSection .container .payFor .description .getInTouch:hover {
  border-radius: 50px;
  transition: 0.3s;
  background: transparent linear-gradient(1deg, #2e92d9 0%, #1791d8 100%) 0% 0% no-repeat padding-box;
}
.qualifiedSection .container .ourProject {
  width: 42%;
}
.qualifiedSection .container .ourProject .completeReview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.qualifiedSection .container .ourProject .completeReview .complete {
  width: 47.5%;
  margin: 15px 0 0 0;
}
.qualifiedSection .container .ourProject .completeReview .review {
  width: 47.5%;
}
.qualifiedSection .container .ourProject .box {
  background: #FFFFFF;
  box-shadow: 0px 0px 16px #1791D829;
  padding: 32px 16px 32px 19px;
  margin: 0 0 20px 0;
  min-height: 223px;
}
.qualifiedSection .container .ourProject .box .title h3 {
  color: #3B3663;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
}
.qualifiedSection .container .ourProject .box .title h3 .number {
  font-size: 44px;
  font-weight: 700;
  display: inline-block;
}
.qualifiedSection .container .ourProject .box .title h3 .counter:after {
  content: '+';
  clear: both;
}
.qualifiedSection .container .ourProject .box .title h3 span {
  display: inline-block;
}
.qualifiedSection .container .ourProject .box .title h3 span:last-child {
  position: relative;
  top: -5px;
  padding: 0 0 0 10px;
}
.qualifiedSection .container .ourProject .box .title h3 .color1 {
  color: #1791D8;
  max-width: 65px;
  width: 100%;
}
.qualifiedSection .container .ourProject .box .title h3 .color2 {
  color: #2FB7BC;
  max-width: 70px;
  width: 100%;
}
.qualifiedSection .container .ourProject .box .contain {
  color: #6E6B89;
  font-size: 18px;
  padding: 17px 0 0 0;
  line-height: 29px;
}
.qualifiedSection .container .ourProject .box.blue {
  background: #1791D8;
  box-shadow: 0px 0px 16px #1791D829;
}
.qualifiedSection .container .ourProject .box.blue .title span {
  color: #FFFFFF;
}
.qualifiedSection .container .ourProject .box.blue .contain {
  color: #FFFFFF;
}
/*qualifiedSection*/
.faqSection {
  overflow: hidden;
}
.faqSection .container {
  padding: 0 85px;
}
.faqSection .container .ourFaqContent {
  padding: 106px 0 0 0px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-left: -6px;
}
.faqSection .container .ourFaqContent h3 {
  text-align: center;
  font: normal normal bold 20px/34px Dosis;
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  margin-bottom: 0.2%;
}
.faqSection .container .ourFaqContent .titleArea h2 {
  text-align: center;
  font: normal normal bold 38px/47px Dosis;
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
}
.faqSection .container .optionsRap {
  margin: 2.8% auto 0;
  vertical-align: middle;
}
.faqSection .container .optionsRap .whyOptions {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.faqSection .container .optionsRap .whyOptions .optsns {
  width: 50%;
  float: left;
}
.faqSection .container .optionsRap .whyOptions .optsns img {
  width: 100%;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft {
  margin: 0 auto;
  text-align: center;
  margin-top: 5.5%;
  padding-right: 5%;
  width: 50%;
  position: relative;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft img {
  height: auto;
  max-width: 510px;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .faqAnimation {
  width: 5%;
  position: absolute;
  animation: grow-shrink 1.5s infinite alternate;
  z-index: 10;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .first {
  top: -4%;
  left: 5%;
  height: 25%;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .second {
  top: -12%;
  left: 18%;
  width: 4%;
  height: 25%;
  animation: shrink-grow 1.5s infinite alternate;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .third {
  top: 2%;
  left: 23%;
  width: 3%;
  height: 25%;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .four {
  top: -15%;
  left: 41%;
  width: 3%;
  height: 25%;
  animation: shrink-grow 1.5s infinite alternate;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .five {
  top: -3%;
  left: 40%;
  width: 6%;
  height: 25%;
}
.faqSection .container .optionsRap .whyOptions .optionsLeft .faqMouseMove .six {
  top: 2%;
  left: 66%;
  height: 25%;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel {
  max-width: 800px;
  margin: auto;
  padding-bottom: 100px;
  float: left;
  padding-top: 10%;
  margin-left: 9%;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tabs {
  border-radius: 8px;
  overflow: hidden;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab {
  width: 100%;
  color: white;
  overflow: hidden;
  border-bottom: 1px solid #EFF5F3;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab input {
  opacity: 0;
  display: none;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab .label {
  display: flex;
  justify-content: space-between;
  padding: 8px 29px 10px 0px;
  cursor: pointer;
  margin: 0;
  font-weight: bold;
  letter-spacing: 1.08px;
  color: #222222;
  position: relative;
  line-height: 41px;
  padding-left: 4px;
  max-width: 85%;
  /* Icon */
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab .label:hover {
  opacity: 0.3;
  transition: 0.3s;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab .label::before {
  content: '';
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 5px;
  background: #D1D0D1;
  position: absolute;
  right: 0;
  top: 31px;
  transform: translateY(-50%);
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab .label::after {
  content: '';
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 5px;
  background: #D1D0D1;
  position: absolute;
  right: 0;
  top: 31px;
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab .content {
  max-height: 0;
  padding: 0;
  font-size: 18px;
  line-height: 19px;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel .tab .close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  cursor: pointer;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel input:checked + .label::after {
  transform: rotate(0);
  content: none;
}
.faqSection .container .optionsRap .whyOptions .optionsRight .faqContentsVessel input:checked ~ .content {
  max-height: 100vh;
  padding: 0;
  padding-bottom: 10px;
}
/*our-project-section*/
.our-project-section {
  background: #F2F9FD 0% 0% no-repeat padding-box;
}
.our-project-section .container {
  padding: 75px 84px 75px 85px;
}
.our-project-section .container .title-section h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0 0 7px 0;
  margin: 0;
}
.our-project-section .container .title-section p {
  text-align: center;
  font-size: 38px;
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0 0 53px 0;
  margin: 0;
}
.our-project-section .container .box-inner .owl-item {
  padding: 0 0 70px 0;
}
@media (max-width: 768px) {
  .our-project-section .container .box-inner .owl-item {
    padding: 0 20px 35px 20px;
  }
}
.our-project-section .container .box-inner .box-contain {
  background-color: #FFF;
  box-shadow: 9px 21px 27px #00000029;
}
.our-project-section .container .box-inner .box-contain .top {
  background: #F7FCFF;
  padding: 0;
}
.our-project-section .container .box-inner .box-contain .top a img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.our-project-section .container .box-inner .box-contain .top a img:hover {
  cursor: pointer;
}
.our-project-section .container .box-inner .box-contain:nth-child(2) {
  margin: 0 4%;
}
/*End our-project-section*/
/*videoSection*/
.videoSection {
  background: #EAF2F7;
  padding: 75px 0;
  margin: 0 0 141px 0;
}
.videoSection .container {
  padding: 0px 84px 0px 85px;
  max-width: 1280px;
  margin: 0px auto;
}
.videoSection .container .groupTeam {
  position: relative;
  max-width: 1111px;
  height: 544px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 0;
  display: block;
  padding-top: 26.25%;
  border-radius: 20px;
}
.videoSection .container .groupTeam .responsiveIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
/*End videoSection*/
.ourTeamSection .container {
  padding: 50px 85px 135px 85px;
}
.ourTeamSection .container .titleSection h3 {
  text-align: center;
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 7px 0;
  margin: 0;
}
.ourTeamSection .container .titleSection p {
  text-align: center;
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
  font-size: 38px;
  font-weight: 700;
  padding: 0 0 49px 0;
  margin: 0;
}
.ourTeamSection .container .innerBox .imageBox .image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.ourTeamSection .container .innerBox .imageBox .image img:hover {
  box-shadow: 0px 0px 21px #73CBFD67;
}
.ourTeamSection .container .innerBox .namePosition p {
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  padding: 21px 0 4px 0;
}
.ourTeamSection .container .innerBox .namePosition h3 {
  letter-spacing: 0px;
  color: #1791D8;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
.ourTeamSection .container .innerBox .owl-nav button {
  position: absolute;
  top: -80px;
  bottom: 0;
  color: #000;
}
.ourTeamSection .container .innerBox .owl-nav button .prev-slide {
  background: url("../images/icon_right_arrow.png") no-repeat scroll 0 0;
  background-size: 75%;
  width: 20px;
  height: 38px;
}
.ourTeamSection .container .innerBox .owl-nav button .next-slide {
  background: url("../images/icon_left_arrow.png") no-repeat scroll 0 0;
  background-size: 75%;
  width: 20px;
  height: 38px;
}
.ourTeamSection .container .innerBox .owl-nav button:hover {
  background-color: transparent !important;
}
.ourTeamSection .container .innerBox .owl-nav .owl-prev {
  right: -50px;
}
.ourTeamSection .container .innerBox .owl-nav .owl-next {
  left: -50px;
}
.ourTeamSection .container .innerBox .owl-nav .owl-prev.disabled {
  display: none;
}
.ourTeamSection .container .innerBox .owl-nav .owl-next.disabled {
  display: none;
}
/*End our-teamsection*/
/*our-testimonial-section*/
.our-testimonial-section .container {
  padding: 0 23px 138px 24px;
}
.our-testimonial-section .container .title-section h2 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  padding: 0 0 7px 0;
}
.our-testimonial-section .container .title-section p {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  padding: 0 0 56px 0;
}
.our-testimonial-section .container #testimonial-slider .item {
  max-width: 634px;
  margin: 0px auto;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain {
  padding: 43px 22px 32px 22px;
  box-sizing: border-box;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .inner-contain {
  position: relative;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .inner-contain p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #85858B;
  margin: 0;
  padding: 0 0 33px 0;
  line-height: 29px;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .inner-contain p::before {
  width: 50px;
  height: 50px;
  content: " ";
  background-image: url("../images/icon_testimonial01.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: -70px;
  top: -19px;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .inner-contain p::after {
  width: 50px;
  height: 50px;
  content: " ";
  background-image: url("../images/icon_testimonial02.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: -60px;
  bottom: 31px;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .bottom-contain {
  max-width: 305px;
  margin: 0px auto;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .bottom-contain .left {
  float: left;
  width: 50px;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .bottom-contain .right {
  float: left;
  width: calc(100% - 50px);
  padding: 0 0 0 19px;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .bottom-contain .right h2 {
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 4px 0;
  text-align: left;
}
.our-testimonial-section .container #testimonial-slider .item .item-contain .bottom-contain .right p {
  letter-spacing: 0px;
  color: #6E6B89;
  font-size: 17px;
  margin: 0;
  text-align: left;
}
.our-testimonial-section .container #testimonial-slider .owl-dots .owl-dot span {
  height: 15px;
  width: 15px;
  background: #FFFFFF;
  border: 1px solid #73CBFD;
}
.our-testimonial-section .container #testimonial-slider .owl-dots .owl-dot:hover span,
.our-testimonial-section .container #testimonial-slider .owl-dots .owl-dot.active span {
  background: #73CBFD;
}
/*End our-testimonial-section*/
/*ourBlog*/
.ourBlog .container {
  padding: 0 85px 135px 85px;
}
.ourBlog .container .titleSection h3 {
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 20px;
  padding: 0 0 7px 0;
  margin: 0;
  text-align: center;
}
.ourBlog .container .titleSection p {
  letter-spacing: 0px;
  color: #3B3663;
  font-weight: 700;
  font-size: 38px;
  padding: 0 0 55px 0;
  margin: 0;
  text-align: center;
}
.ourBlog .container .containSection .rowItem {
  margin: 0 0 40px 0;
}
.ourBlog .container .containSection .rowItem .rowContain {
  float: left;
  width: 30.6%;
  box-shadow: 0px 0px 13px #73CBFD6F;
  min-height: 414px;
  margin-bottom: 40px;
  display: none;
}
.ourBlog .container .containSection .rowItem .rowContain .link {
  display: block;
  text-decoration: none;
}
.ourBlog .container .containSection .rowItem .rowContain .link .image {
  overflow: hidden;
  box-sizing: border-box;
}
.ourBlog .container .containSection .rowItem .rowContain .link .image img {
  width: 100%;
  transition: 0.3s;
}
.ourBlog .container .containSection .rowItem .rowContain .link .image img:hover {
  transition: 0.3s;
  transform: scale(1.1);
}
.ourBlog .container .containSection .rowItem .rowContain .link .bottomContain {
  padding: 12px 25px 22px 26px;
}
.ourBlog .container .containSection .rowItem .rowContain .link .bottomContain h3 {
  font-size: 26px;
  font-weight: 700;
  color: #3B3663;
  text-transform: capitalize;
  margin: 0;
  padding: 12px 0 24px 0;
  line-height: 32px;
}
.ourBlog .container .containSection .rowItem .rowContain .link .bottomContain p {
  font-size: 18px;
  font-weight: 500;
  color: #85858B;
  text-transform: capitalize;
  margin: 0;
  padding: 0px 0 0px 0;
  line-height: 29px;
}
.ourBlog .container .containSection .rowItem .rowContain:nth-child(3n+2) {
  margin: 0 4%;
}
.ourBlog .container .containSection .loadMoreContent {
  display: none;
}
.ourBlog .container .containSection .loadMoreBtn a {
  display: block;
  text-decoration: none;
  background: transparent linear-gradient(265deg, #73CBFD 0%, #1791D8 100%) 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  font-size: 18px;
  border-radius: 6px;
  width: 163px;
  padding: 15px 0;
  margin: 0px auto;
  text-align: center;
}
.ourBlog .container .containSection .loadMoreBtn a:hover {
  border-radius: 50px;
  transition: 0.3s;
  background: transparent linear-gradient(1deg, #2e92d9 0%, #1791d8 100%) 0% 0% no-repeat padding-box;
}
.ourBlog .container .containSection .loadMoreBtn a:hover::after {
  border: none;
}
/*End ourBlog*/
/*contactUsSection*/
.contactUsSection .container {
  padding: 0 85px;
}
.contactUsSection .container .innerContain {
  padding: 20px 0 120px 0;
  display: flex;
  flex-direction: row;
}
.contactUsSection .container .innerContain .imageSection {
  width: 30%;
  padding: 50px 0 0 0;
  position: relative;
}
.contactUsSection .container .innerContain .imageSection img {
  width: 100%;
}
.contactUsSection .container .innerContain .imageSection .manMouseMove {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.contactUsSection .container .innerContain .imageSection .manMouseMove .animationImg {
  width: 14%;
  height: 22%;
  position: absolute;
  top: 4%;
  left: 8%;
  animation: grow-shrink 1.5s infinite alternate;
  z-index: 10;
}
.contactUsSection .container .innerContain .imageSection .manMouseMove .first {
  top: 6%;
  left: 41%;
  height: 20%;
}
.contactUsSection .container .innerContain .imageSection .manMouseMove .second {
  top: 5%;
  left: 52%;
  height: 20%;
}
.contactUsSection .container .innerContain .contactFormSection {
  width: 70%;
  padding: 0 0 0 15%;
}
.contactUsSection .container .innerContain .contactFormSection .titleSection h2 {
  letter-spacing: 0px;
  color: #73CBFD;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 7px 0;
  text-align: center;
}
.contactUsSection .container .innerContain .contactFormSection .titleSection p {
  letter-spacing: 0px;
  color: #3B3663;
  text-transform: capitalize;
  font-size: 38px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 20px 0;
  text-align: center;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection {
  border-bottom: 1px solid rgba(112, 112, 112, 0.24);
  padding: 8px 0px 8px 0;
  margin: 0 0 20px 0;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .labelWithInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .labelWithInput .inputLabel {
  font-size: 15px;
  font-weight: 500;
  color: #6E6B89;
  width: 78px;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .labelWithInput .inputText {
  flex: 1;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .labelWithInput .inputText input[type="text"] {
  width: 100%;
  height: 30px;
  border: none;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .labelWithInput .inputText input:focus {
  outline: none;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .labelWithInput textarea {
  width: 100%;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .inputSection .error {
  color: red;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection {
  border: none;
  margin-bottom: 0;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput {
  flex-direction: column;
  align-items: flex-start;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput .input-label {
  width: 100%;
  padding: 0 0 10px 0;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput .inputTextarea {
  width: 100%;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput .inputTextarea textarea {
  min-height: 144px;
  background: #F7F7F7;
  border: none;
  padding: 5px;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput .inputTextarea textarea:focus {
  outline: none;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput .inputTextarea .remain-text {
  color: #6E6B89;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .messageSection .labelWithInput .inputTextarea .remain-text em {
  font-style: normal;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .recaptcha {
  border: none;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .recaptcha .recaptchaErrorMessage {
  color: red;
  padding: 5px 0 0 0;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .buttonSection {
  border: none;
  justify-content: end;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .buttonSection input {
  background: transparent linear-gradient(265deg, #73CBFD 0%, #1791D8 100%) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  width: 163px;
  text-align: center;
  padding: 17px 0 16px 0;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.contactUsSection .container .innerContain .contactFormSection .formSection .buttonSection input:hover {
  border-radius: 50px;
  transition: 0.3s;
  background: transparent linear-gradient(1deg, #2e92d9 0%, #1791d8 100%) 0% 0% no-repeat padding-box;
}
/*End contactUsSection*/
/*Email modal*/
.modalPopup {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
}
.modalPopup .modalContent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modalPopup .modalContent p {
  font-weight: 700;
  font-size: 20px;
}
.modalPopup .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: relative;
  top: -19px;
  left: 7px;
}
.modalPopup .close:hover,
.modalPopup .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
/*End email modal*/
/* Style the footer */
#footer {
  background: #1E4067 0% 0% no-repeat padding-box;
  padding: 48px 0px 20px;
  text-align: center;
  min-height: 113px;
}
#footer #footerInner {
  max-width: 1280px;
  margin: 0px auto;
}
#footer #footerInner .footerTop {
  width: 100%;
  padding: 0px 85px 0 85px;
  display: flex;
  justify-content: space-between;
}
#footer #footerInner .footerTop .cbGroup {
  flex: 2;
  position: relative;
}
#footer #footerInner .footerTop .cbGroup .headline {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  text-align: left;
}
@media (max-width: 768px) {
  #footer #footerInner .footerTop .cbGroup .headline {
    margin-bottom: 5px;
  }
}
#footer #footerInner .footerTop .cbGroup .cbGroupIcon {
  text-align: left;
  position: absolute;
  width: 100%;
  top: 55%;
  transform: translateY(-55%);
}
@media (max-width: 768px) {
  #footer #footerInner .footerTop .cbGroup .cbGroupIcon {
    top: initial;
    position: initial;
    transform: initial;
  }
}
#footer #footerInner .footerTop .cbGroup .cbGroupIcon a {
  margin-right: 5%;
  display: inline-block;
}
#footer #footerInner .footerTop .cbGroup .cbGroupIcon a img {
  max-width: 87px;
  width: 100%;
}
#footer #footerInner .footerTop .cbGroup .cbGroupIcon a:hover {
  opacity: 0.3;
  transition: 0.3s;
}
#footer #footerInner .footerTop .cbGroup .cbGroupIcon a:nth-child(3) img {
  background-color: #fff;
}
#footer #footerInner .footerTop .address {
  flex: 3;
}
#footer #footerInner .footerTop .address .headline {
  text-align: left;
  color: #FFFFFF;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  padding: 0 0 26px 0;
}
#footer #footerInner .footerTop .address p {
  text-align: left;
  color: #FDFDFE;
  font-size: 17px;
  font-weight: normal;
  margin: 0;
  padding: 0 0 26px 0;
}
#footer #footerInner .footerTop .address .dflexAddress {
  display: flex;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock {
  width: 95%;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock h2 {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #FDFDFE;
  text-align: left;
  margin-bottom: 20px;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .addressBlock {
  margin: 0;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .addressBlock .address {
  float: left;
  width: calc(100% - 38px);
  margin: 0;
  font-size: 14px;
  color: #FDFDFE;
  line-height: 22px;
  text-align: left;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .phoneBlock {
  margin: 0;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .phoneBlock .phone {
  float: left;
  width: calc(100% - 32px);
  margin: 0;
  font-size: 14px;
  color: #FDFDFE;
  text-align: left;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .mailBlock {
  margin: 0;
  padding: 0 0 0 0;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .mailBlock .mail {
  float: left;
  width: calc(100% - 59px);
  margin: 0;
  font-size: 14px;
  color: #FDFDFE;
  text-align: left;
}
#footer #footerInner .footerTop .address .dflexAddress .addressPhoneMailBlock .mailBlock .mail img {
  margin-top: 5px;
  max-width: 112px;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice {
  width: 100%;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice h2 {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #FDFDFE;
  text-align: left;
  margin-bottom: 20px;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .addressBlock {
  margin: 0;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .addressBlock .address {
  float: left;
  width: calc(100% - 38px);
  margin: 0;
  font-size: 14px;
  color: #FDFDFE;
  line-height: 22px;
  text-align: left;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .phoneBlock {
  margin: 0;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .phoneBlock .phone {
  float: left;
  width: calc(100% - 32px);
  margin: 0;
  font-size: 14px;
  color: #FDFDFE;
  text-align: left;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .mailBlock {
  margin: 0;
  padding: 0 0 0 0;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .mailBlock .mail {
  float: left;
  width: calc(100% - 59px);
  margin: 0;
  font-size: 14px;
  color: #FDFDFE;
  text-align: left;
}
#footer #footerInner .footerTop .address .dflexAddress .japanOffice .mailBlock .mail img {
  margin-top: 5px;
  max-width: 112px;
}
#footer #footerInner .footerTop .sns {
  text-align: left;
}
#footer #footerInner .footerTop .sns h2 {
  font-size: 17px;
  font-weight: 500;
  color: #FDFDFE;
  margin: 0 0 22px 0;
}
@media (max-width: 768px) {
  #footer #footerInner .footerTop .sns h2 {
    margin-bottom: 3px;
  }
}
#footer #footerInner .footerTop .sns ul {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
@media (max-width: 768px) {
  #footer #footerInner .footerTop .sns ul {
    grid-template-columns: 40px 40px 40px 40px 40px 40px;
  }
}
#footer #footerInner .footerTop .sns ul li {
  list-style-type: none;
  margin: 0;
}
#footer #footerInner .footerTop .sns ul li a {
  display: block;
}
#footer #footerInner .footerTop .sns ul li a img {
  max-width: 40px;
  width: 100%;
  display: block;
}
#footer #footerInner .footerTop .sns ul li a:hover {
  transition: 0.3s;
}
#footer #footerInner .footerBottom {
  padding: 0 85px 0 85px;
}
#footer #footerInner .footerBottom .top {
  border-top: 1px solid #92B3D9;
  height: 1px;
  max-width: 100%;
  margin: 48px auto 15px auto;
}
#footer #footerInner .footerBottom .bottom {
  padding: 0;
}
#footer #footerInner .footerBottom .bottom div {
  font-size: 11px;
  letter-spacing: 0px;
  color: #FDFDFE;
  text-align: center;
}
/*ourClientProjectSection*/
.ourClientProjectSection .container {
  padding: 0 85px 35px 85px;
}
.ourClientProjectSection .container .itema img {
  display: block;
}
.ourClientProjectSection .container .bx-wrapper {
  border: none;
  box-shadow: none;
  background: transparent;
}
.ourClientProjectSection .container .bx-wrapper #ourteamProjectSlider .item {
  text-align: center;
}
.ourClientProjectSection .container .bx-wrapper #ourteamProjectSlider .item img {
  display: initial;
  width: initial !important;
}
/*End ourClientProjectSection*/
/*whatMakesUsDifferent*/
.whatMakesUsDifferent .container {
  padding: 0 128px 80px 128px;
}
.whatMakesUsDifferent .container .titleSection h3 {
  font-size: 38px;
  font-weight: 700;
  color: #3B3663;
  text-transform: capitalize;
  text-align: center;
  padding: 0 0 103px 0;
}
.whatMakesUsDifferent .container .bottomSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 206px;
  grid-row-gap: 72px;
}
@media (max-width: 768px) {
  .whatMakesUsDifferent .container .bottomSection {
    grid-template-columns: auto;
  }
}
.whatMakesUsDifferent .container .bottomSection .item .imageTitle {
  display: grid;
  grid-template-columns: 62px auto;
  align-items: center;
  padding: 0 0 51px 0;
}
.whatMakesUsDifferent .container .bottomSection .item .imageTitle .image img {
  width: 100%;
}
.whatMakesUsDifferent .container .bottomSection .item .imageTitle .heading {
  font-size: 30px;
  color: #000000;
  font-weight: 500;
  padding: 0 0 0 27px;
}
.whatMakesUsDifferent .container .bottomSection .item .content {
  text-align: center;
  font-size: 18px;
  line-height: 29px;
  color: #2E2E2E;
}
/*End whatMakesUsDifferent*/
.notFount {
  position: relative;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  width: 100%;
}
.notFount h1 {
  margin: 20px auto;
  color: #3B3663;
  font-size: 50px;
  font-family: 'Dosis', sans-serif;
}
